export const common = {
    lbs: 2.20462262,
    groupName1: 'web-normal-group',
    groupName2: 'web-discount-group',
    monthList: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sept',
        'Oct',
        'Nov',
        'Dec',
    ],
    devFirebase: {
        apiKey: "AIzaSyBCn-nI-CJczQTFQpOERGp_ZWpsuDWjdNw",
        authDomain: "litely-dev.firebaseapp.com",
        projectId: "litely-dev",
        storageBucket: "litely-dev.appspot.com",
        messagingSenderId: "831314747392",
        appId: "1:831314747392:web:56bfb072bba134ee3578e5",
        measurementId: "G-DGXRBHE8YV"
    },
    proFirebase: {
        apiKey: "AIzaSyB9MChWqfgTn0xba2asju2VCsfe3E8j8II",
        authDomain: "litely-36855.firebaseapp.com",
        projectId: "litely-36855",
        storageBucket: "litely-36855.appspot.com",
        messagingSenderId: "836387540158",
        appId: "1:836387540158:web:6ce9ece2b128e71773a2ef",
        measurementId: "G-2T7TL5TNYT"
    },
    // 检测是否是正确的username
    checkUsername(name) {
        if (name.trim().length == 0) return false;
        let r = /[^a-zA-Z0-9\s_-\u4e00-\u9fa5]/;
        return !r.test(name.trim());
    },
    // 检测是否是email
    isEmail(string) {
        return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(string)
    },
    imgToBase64(imageUrl, imageBucket, imgKey) {
        if (imgKey == "" || imgKey == undefined) return "";
        return (
            imageUrl + "/" +
            window.btoa(
                `{"bucket": "${imageBucket}","key": "${imgKey}","edits": { "resize": {"width": 200, "height": 200, "fit": "outside"} } }`
            )
        );

    },
    // 每个月的默认金额
    oneMonthPrice: 21.99,
    // 获取折扣
    getDiscount(priceInfo) {
        if (priceInfo.value_type == 0) {
            // 当类型为0时value是月份 
            // 折扣(百分比) =（ 原价 -  美分 / 100 ） / 每月默认金额 * 100
            return parseInt((this.oneMonthPrice - priceInfo.first_price / 100) / this.oneMonthPrice * 100) + '%';
        } else if (priceInfo.value_type == 1) {
            // 当类型为0时value是天数 
            // 折扣(百分比) =（ 原价 - 美分 / 100 * 30 ）/ 每月默认金额 * 100
            return parseInt((this.oneMonthPrice - priceInfo.first_price / 100 * 30) / this.oneMonthPrice);
        }
        return '';
    },
    // 获取折扣金额
    getDiscountPrice(priceInfo) {
        if (priceInfo.value_type == 0) {
            // 当类型为0时value是月份 
            // 折扣金额 = 每月默认金额 * 多少个月 - 美分 / 100
            return (this.oneMonthPrice * priceInfo.value - priceInfo.first_price / 100).toFixed(2);
        } else if (priceInfo.value_type == 1) {
            // 当类型为0时value是天数 
            // 折扣金额 = 每月默认金额 / 30天 * 多少天 - 美分 / 100
            return (this.oneMonthPrice / 30 * priceInfo.value - priceInfo.first_price / 100).toFixed(2);
        }
        return '';
    }
}